
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: none;
    display: block;
    height: 100%;
}

video {
    max-height: none;
    display: block;
    height: 100%;
}