.mobile-table table th, .mobile-table table td{
    font-size: small;
}

.mobile-table .gall-table-wraper table {
    position: absolute;
    z-index: 1000;
}

.col {
    margin-bottom: 15px;
}
