.error {
    color: red;
    margin: 0px;
}

.spinner-border {
    width: 100px!important;
    height: 100px!important;
    position: absolute;
    left: 45%;
    top: 45%;
}

.gall-table-wraper, .gall-qrcode-wraper {
    position: relative;
}

.gall-table-wraper table {
    position: absolute;
    top: 0;
    background: #000000bd;
    border-bottom: 3px solid #fff;
}

.gall-table-wraper table tr th:nth-child(2), .gall-table-wraper table tr td:nth-child(2) {
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;

}

.gall-table-wraper table tr th, .gall-table-wraper table tr td {
    padding-left: 20px;
    padding-right: 20px;

}

.gall-table-wraper table tr th {
    color: #ffffff;
}

.qrcode-img {
    position: absolute;
    max-width: 12.5%;
    bottom: 5%;
    left: 5%;


}

table {
    letter-spacing: 5px;
}

