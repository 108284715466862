
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td {
    text-align: left;
    font-size: 40px;
    color: white;
}

th {
    font-size: 40px;
    color: #9fcdff;
}

.red_text {
    color: #ea2c0d;
    font-weight: bold;
}

.yellow_text {
    color: #d39e00;
    font-weight: bold;
}

.green_text {
    color: #28a745;
    font-weight: bold;
}



html {
    height: 100%;
    background-color: #0b2e13;
}

body {
    height: 100%;
}

.container {
    height: 100%;
    background-color: #0b2e13;
}

.App {
    height: 100%;
    background-color: #0b2e13;
}



#root {
    height: 100%;
}






