h1 {
    text-align: center;
    margin: 40px;
    color: white;
}

.col {
    margin-bottom: 15px;
}


/*.switch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}*/

input[type="checkbox"] {
    position: relative;
    width: 40px;
    height: 20px;
    -webkit-appearance: none;
    outline: none;
    background: #c6c6c6;
    border-radius: 20px;
    transition: .5s;
    box-shadow: inset 0 0 5px rgba(0,0,0,.2);
}

input:checked[type="checkbox"] {
    background: #40e551
}

input[type="checkbox"]:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: #fff;
    transition: .5s;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
}

input:checked[type="checkbox"]:before {
    left: 20px;
}

/*span {
    position: absolute;
    left: 80px;
    top: 53px;
}*/

span {
    padding-left: 10px;
}

